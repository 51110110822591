<script setup lang="ts">
</script>

<template>
  <NuxtPage/>
  <Toast group="app"/>
</template>

<style scoped>

</style>
